<script setup lang="ts">
import { assetLink } from "@/common/util"
import {
    articleSupportsSubItem,
    calculateSubItemPrice,
    defaultPackagingType,
    pricePerGlasJar,
    type ARTICLE_SUB_ITEM_VALUE,
} from "@/model/article-sub-items"
import CanDropdown from "@/shop/components/select/CanDropdown.vue"
import { useCartStore, type CartItem } from "@/shop/store/useCartStore"
import { $articleUnit } from "@/shop/util"
import { $currency } from "@/vf"
import { computed } from "vue"
import PriceSpinner from "./PriceSpinner.vue"

const props = defineProps<{
    item: CartItem
    isCart: boolean
    error?: {
        errorType?: "pendingOrder" | "file" | "invalidZipForCarrierService" | "outOfStock"
        articles?: string[]
    }
}>()

const cartStore = useCartStore()

const pack = computed<ARTICLE_SUB_ITEM_VALUE | undefined>({
    get() {
        return props.item.subItems?.filter(i => i.type === "packaging")?.[0]?.value
    },

    set(newValue) {
        if (newValue === undefined) {
            newValue = defaultPackagingType
        }

        cartStore.changeSubitem(props.item.article, "packaging", newValue)
    },
})

const humidity = computed<ARTICLE_SUB_ITEM_VALUE | undefined>({
    get() {
        return props.item.subItems?.filter(i => i.type === "humidity")?.[0]?.value
    },

    set(newValue) {
        if (newValue === undefined) {
            newValue = defaultPackagingType
        }

        cartStore.changeSubitem(props.item.article, "humidity", newValue)
    },
})

const enablePackagingSelection = computed(() => {
    return articleSupportsSubItem(props.item.article.type ?? "", "packaging")
})

const enableHumiditySelection = computed(() => {
    return articleSupportsSubItem(props.item.article.type ?? "", "humidity")
})
</script>

<template>
    <div class="row checkout-cart-item align-items-center my-5">
        <div class="col-md-2 d-none d-md-block">
            <div class="pr-4">
                <img v-if="!item.article.imageName" src="@/shop/assets/blossom.png" class="img-fluid" />
                <img
                    v-if="item.article.imageName"
                    class="img-fluid"
                    :src="assetLink('/uploads/media/' + item.article.imageName)"
                />
            </div>
        </div>
        <div class="col-10 col-md-5">
            <div class="checkout-cart-item-brand">
                {{ item.article.publicNamePrimary }}
            </div>
            <div class="checkout-cart-item-name">{{ item.article.publicNameSecondary }}</div>
            <div
                v-if="item.article.type === 'essence' || item.article.type === 'cannabis'"
                class="checkout-cart-item-info"
            >
                THC {{ item.article.thc }} % &middot; CBD {{ item.article.cbd }} %
            </div>

            <div
                v-if="
                    item?.article?.id && error?.errorType == 'outOfStock' && error?.articles?.includes(item.article.id)
                "
                class="alert alert-danger"
            >
                Dieses Produkt ist nicht oder nicht in ausreichender Menge auf Lager.
            </div>

            <div v-if="item.stockStatus === 'batch-overflow'" class="alert alert-info my-2">
                <b>Achtung</b>
                wir müssen diese Position auf zwei Chargen aufteilen. Es ist möglich, dass Teile Deiner Bestellung
                andere Werte als die angegebenen haben werden.
            </div>

            <div v-if="item.stockStatus === 'not-enough-stock'" class="alert alert-warning my-2">
                Wir haben die gewünschte Menge leider nicht auf Lager.
            </div>

            <div v-if="item.stockStatus === 'not-in-stock'" class="alert alert-warning" my-2>
                Diese Sorte ist aktuell leider nicht lieferbar.
            </div>
        </div>

        <div class="col-12 col-md-5">
            <div class="mt-3 mt-md-0 d-flex justify-content-between align-items-lg-end flex-column flex-lg-row">
                <div>
                    <div class>
                        <b>Menge</b>
                        <div class="text-muted mb-2">
                            <PriceSpinner
                                :price="(item.singlePrice ?? 0) / (item.article.packageAmount ?? 1)"
                            ></PriceSpinner>
                            je {{ $articleUnit(item.article.unit!) }}
                        </div>
                    </div>

                    <div class="d-flex align-items-center" style="gap: 0.25rem">
                        <button
                            v-if="isCart"
                            class="btn btn-primary btn-sm py-2 px-4"
                            :disabled="cartStore.isMinimumAmount(item.article)"
                            @click="cartStore.changeAmount(item.article, item.amount - 1)"
                        >
                            -
                        </button>
                        <span class="d-inline-block text-center" style="min-width: 80px">
                            {{ item.amount }}
                            {{ item.article.packageAmount !== 1 ? "x " + item.article.packageAmount : "" }}
                            {{ item.article.unit }}
                        </span>
                        <button
                            v-if="isCart"
                            class="btn btn-primary btn-sm py-2 px-4"
                            @click="cartStore.changeAmount(item.article, item.amount + 1)"
                        >
                            +
                        </button>
                        <button
                            v-if="isCart"
                            class="btn btn-danger btn-sm py-2 px-4"
                            @click="cartStore.removeFromCart(item.article)"
                        >
                            <i class="fa fa-fw fa-trash"></i>
                        </button>
                    </div>
                </div>
                <div class="text-lg-right mt-3 mt-lg-0">
                    <div class="article-price">
                        <PriceSpinner :price="item.totalPrice"></PriceSpinner>
                    </div>
                    <div v-if="item.subItemsTotal != undefined && item.subItemsTotal > 0" class="mt-1">
                        + {{ $currency(item.subItemsTotal) }}
                    </div>
                    <div v-if="item.stockStatus === 'not-in-stock'">
                        <div
                            style="width: 1rem; height: 1rem; border-radius: 50%"
                            class="bg-danger d-inline-block"
                        ></div>
                        Nicht Lieferbar
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="enablePackagingSelection || enableHumiditySelection" class="row">
        <div v-if="enablePackagingSelection" class="col-md-6 col-12">
            <b>{{ isCart ? "Packmittel wählen" : "Packmittel" }}</b>

            <div v-if="!isCart">
                {{ pack }}
            </div>
            <CanDropdown v-else v-model="pack">
                <template #options="{ select }">
                    <div class="px-4">
                        <button type="button" class="wizard-button" @click="select('Doy-Pack')">
                            <div class="d-flex justify-content-between">
                                <b>Doy-Pack</b>
                                <b>0,00 €</b>
                            </div>
                            <p>
                                Unsere beschichteten und wiederverschließbaren Beutel halten deine Blüten frisch und
                                werden von uns versiegelt.
                            </p>
                        </button>

                        <button type="button" class="wizard-button" @click="select('Chubby Gorilla Dose')">
                            <div class="d-flex justify-content-between">
                                <b>Chubby Gorilla Dose</b>
                                <b>0,00 €</b>
                            </div>
                            <p>
                                In unseren PE-Dosen von Chubby Gorilla mit besonders großer Öffnung und Kindersicherung
                                fühlen sich deine Blüten auch zu Hause gut aufgehoben. Bei geringem Füllstand fügen wir
                                ein Papier als Rüttelschutz mit ein.
                            </p>
                        </button>

                        <button type="button" class="wizard-button" @click="select('Glastiegel')">
                            <div class="d-flex justify-content-between">
                                <b>Glastiegel</b>
                                <b>{{
                                    $currency(
                                        calculateSubItemPrice(
                                            "Glastiegel",
                                            item.amount * (item.article.packageAmount ?? 1),
                                        ),
                                    )
                                }}</b>
                            </div>
                            <p>
                                In unser Glasgefäß aus Braunglas mit Kindersicherung füllen wir maximal 20g ab. Bei
                                größerer Bestellmenge verwenden wir mehrere Gläser. Preis
                                {{ $currency(pricePerGlasJar) }} pro Glas.
                            </p>
                        </button>
                    </div>
                </template>
            </CanDropdown>
        </div>
        <div v-if="enableHumiditySelection" class="col-md-6 col-12">
            <b>{{ isCart ? "Integra wählen" : "Integra" }}</b>

            <div v-if="!isCart">
                {{ humidity }}
            </div>

            <CanDropdown v-else v-model="humidity">
                <template #options="{ select }">
                    <div class="px-4 pt-2">
                        <p>
                            Wir legen auf Wunsch jeder Bestellung pro bestellter Sorte 1x4g Integra gratis bei. Bitte
                            wähle deinen gewünschten RH-Wert.
                        </p>
                        <button type="button" class="wizard-button" @click="select('Integra Boost 55%')">
                            Integra Boost 55% RH
                        </button>
                        <button type="button" class="wizard-button" @click="select('Integra Boost 62%')">
                            Integra Boost 62% RH
                        </button>
                        <button type="button" class="wizard-button" @click="select('Kein Integra Boost')">
                            Vielen Dank, aber das brauche ich nicht
                        </button>
                    </div>
                </template>
            </CanDropdown>
        </div>
    </div>
    <hr class="my-2" />
</template>
